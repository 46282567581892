<template>
    <div class="c-detail">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="签章费用结算详情" fixed placeholder safe-area-inset-top />
        <DetailBoxSignature :dataJson='dataJson'></DetailBoxSignature>
    </div>
</template>

<script>
import { Toast ,NavBar } from 'vant';
import DetailBoxSignature from '@/components/DetailBoxSignature'
export default {
    name:"detail",
    data(){
        return{
            org_code:this.$route.query.org_code,
            dataJson:{},
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        getDept(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$post(`/finance/api/cfcaFee/list`,{appid: "28f10c6186cc11ebb35f00163e089d08","param":{"settlementOnly": "true"}}).then(res => {
                if (res.code === '200') {
                    this.list = res.data.records
                    this.list.forEach(item => {
                        if(item.org_code == this.org_code){
                            this.dataJson = item
                        }
                    })
                    Toast.clear();
                }
            })
        }
    },
    mounted() {
        this.getDept() 
        
    },
    components:{
        [Toast.name]: Toast,
        [NavBar.name]: NavBar,
        DetailBoxSignature
    }
}
</script>

<style lang="scss" scoped>
    .c-detail{
        background: #F4F4F4;
        height: 100vh;
        
    }
</style>