<template>
    <div class="c-detail">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="调解系统服务费结算详情" fixed placeholder safe-area-inset-top />
        <DetailBox :dataJson='dataJson'></DetailBox>
    </div>
</template>

<script>
import { Toast ,NavBar } from 'vant';
import DetailBox from '@/components/DetailBox'
export default {
    name:"detail",
    data(){
        return{
            deptCode:this.$route.query.deptCode,
            dataJson:{}
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        getDept(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/mediate/getDeptCaseFeeList`).then(res => {
                if (res.code === '200') {
                    this.list = res.data.records
                    this.list.forEach(item => {
                        if(item.deptCode == this.deptCode){
                            this.dataJson = item
                        }
                    })
                    Toast.clear();
                }
            })
        }
    },
    mounted() {
        this.getDept() 
    },
    components:{
        [Toast.name]: Toast,
        [NavBar.name]: NavBar,
        DetailBox
    }
}
</script>

<style lang="scss" scoped>
    .c-detail{
        background: #F4F4F4;
        height: 100vh;
        
    }
</style>