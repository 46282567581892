<template>
    <div class="c-detail">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="法院系统服务费结算详情" fixed placeholder safe-area-inset-top />
        <DetailBoxCourt :dataJson='dataJson'></DetailBoxCourt>
    </div>
</template>

<script>
import { Toast ,NavBar } from 'vant';
import DetailBoxCourt from '@/components/DetailBoxCourt'
export default {
    name:"detail",
    data(){
        return{
           id:this.$route.query.id,
           dataJson:{}
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        getInfo() {
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/dicsPlatformFeeCourt/get/${this.id}`).then(res => {
                if (res.code === '200') {
                    Toast.clear();
                    this.dataJson = res.data
                    this.dataJson.serviceTime = this.$common.CodeValue('PLATFORM_SERVICE_TIME', this.dataJson.serviceTime);

                }
            })
        },
    },
    mounted() {
        this.getInfo()
    },
    components:{
        [Toast.name]: Toast,
        [NavBar.name]: NavBar,
        DetailBoxCourt
    }
}
</script>

<style lang="scss" scoped>
    .c-detail{
        background: #F4F4F4;
        height: 100vh;
        
    }
</style>